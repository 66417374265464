@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500&family=Instrument+Serif&family=Playfair+Display:wght@600&family=Poppins:ital,wght@1,100;1,300;1,600&display=swap');

:root {
  --main-color: #FFF8E6;
  --text-gray: #6E6E6E;
}

.App {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

/* ============ NAVBAR ============= */

nav {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  background-color: var(--main-color)
}

.nav-links{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;
  text-align: center;
  padding-right: 2rem;
  gap: 2rem;
}

.nav-link a{
  text-decoration: none;
  list-style: none;
  font-size: 1.5rem;
  color: var(--text-gray);
  font-family: 'Playfair Display', serif;
  font-weight: 100;
}

.logo {
  height: 300px;
  
}

@media screen and (max-width: 1024px) {
  .nav-links {
  display: none;
  position: absolute;
  top: 80px;
  right: 0;
  height: 100vh;
  width: 70%;
  background-color: #FFF8E6;
  z-index: 1;
  padding-top: 3.5rem;
  transition: all 0.5s ease-in-out;
  flex-wrap: wrap; /* add this property */
  }
  
  .nav-links.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  
  .nav-link {
  margin: 1rem 0;
  width: 50%; /* add this property */
  }
  
  .nav-link a {
  font-size: 2rem;
  color: #6E6E6E;
  }
  
  .logo {
  height: 200px;
  }
  
  .menu-icon {
  display: block;
  cursor: pointer;
  position: fixed;
  top: 30px;
  right: 2rem;
  z-index: 2;
  }
  
  .menu-icon i {
  font-size: 2.5rem;
  color: #6E6E6E;
  }
  }


/* =============== HERO ================= */

.hero {
  background-image: url('elrosal2.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 8rem;
  padding-bottom: 2rem; /* add padding to the bottom */
  text-align: center;
}

.hero-title {
  padding-top: 4rem;
}

.hero-subtitle {
  padding-top: 2rem;
}

.hero h1 {
  font-size: 18rem;
  line-height: 1;
  margin-bottom: 1rem;
  font-family: 'Playfair Display', serif;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  color: #FADC86;
}

.hero h2 {
  font-size: 5rem;
  font-family: 'Poppins', sans-serif;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  color: #fff;
  line-height: 1;
  margin-bottom: 2rem;
}

.hero p {
  font-size: 3rem;
  font-family: 'Poppins', sans-serif;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  color: #fff;
  line-height: 1;
}

@media screen and (max-width: 1024px) {
  .hero {
    height: 80vh;
    }
  .hero h1 {
  font-size: 12rem;
  }
  .hero h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
  }
  .hero p {
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 1rem;
  }
}
@media screen and (max-width: 700px) {
  .hero {
    height: 60vh;
    padding-top: 0;
    }
    .hero-title {
      margin-bottom: 0;
    }
  .hero h1 {
  font-size: 8rem;
  }
  .hero h2 {
  font-size: 3rem;
  margin-bottom: .5rem;
  }
  .hero p {
  font-size: 1rem;
  line-height: 0.5;
  margin-bottom: 1rem;
  }
}

@media screen and (max-width: 450px) {
  .hero {
    height: 50vh;
    padding-top: 0;
  }
  
  .hero-title {
    margin-bottom: 0;
  }
  
  .hero-subtitle {
    padding-top: 1rem;
    margin-bottom: 0;
  }
  
  .hero p {
    padding-top: 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 0.5;
  }
  
  .hero h1 {
    font-size: 4rem;
    margin-bottom: 0;
  }
  
  .hero h2 {
    font-size: 2rem;
    margin-bottom: 0;
  }
}



/* ====================================== */

/* ============= ABOUT US =============== */

.about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}

.about-us h2 {
  font-size: 6rem;
  font-family: 'Poppins', sans-serif;
}

.about-us p {
  font-size: 2rem;
  font-family: 'Poppins', sans-serif;
}

.about-us-main {
  text-align: center;
  max-width: 800px;
  margin-bottom: 50px;
}

.about-us-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
}

.about-us-card {
  max-width: 300px;
  margin: 10px;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
}

.about-us-card img {
  display: block;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
}

.about-us-card h3 {
  margin: 10px 10px 5px 10px;
  font-size: 2.5rem;
  font-family: 'Poppins', sans-serif;
}

.about-us-card p {
  margin: 0 10px 10px 10px;
  font-size: 2rem;
  font-family: 'Poppins', sans-serif;
}

.band {
  height: 20vh;
  background-color: #FFF8E6;
  width: 100%;
}
@media only screen and (max-width: 700px) {
  .band {
    height: 10vh;
    background-color: #FFF8E6;
    width: 100%;
  }
  .about-us h2 {
    font-size: 2rem;
    font-family: 'Poppins', sans-serif;
  }
  
  .about-us p {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
  }
}

@media only screen and (max-width: 450px) {
  .band {
    height: 10vh;
    background-color: #FFF8E6;
    width: 100%;
  }
  .about-us {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
  }
  
  .about-us h2 {
    font-size: 3rem;
  }
  
  .about-us p {
    font-size: 1rem;
  }
}



/* ====================================== */

/* ============= GALLERY ================ */
#carouselExampleControlsNoTouching {
  margin: auto;
  max-height: 100vh;
  overflow: hidden; 
  /* padding-bottom: 4rem; */
}

.carousel-item {
  text-align: center;
  align-items: center;
}

.carousel-item img {
  display: block;
  margin: 0 auto;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

@media only screen and (max-width: 768px) {
  #carouselExampleControlsNoTouching .carousel-item {
    max-height: 60vh;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 450px) {
  #carouselExampleControlsNoTouching .carousel-item {
    max-height: 50vh;
    margin-bottom: 0;
  }
}


/* ====================================== */
/* ============= FOOTER ================= */
.footer {
  background-color: var(--main-color);
  color: var(--text-gray);
  padding: 40px 0;
  text-align: center;
  justify-content: center;
  align-items: center;
}



/* ====================================== */


